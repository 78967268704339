import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getDeviceList_returns_data_item } from "../api/getDeviceList";

export const DeviceSliceInitialState: getDeviceList_returns_data_item[] = [];

export const DeviceSlice = createSlice({
    name: 'devices',
    initialState: DeviceSliceInitialState,
    reducers: {
        populate: (state: getDeviceList_returns_data_item[], action: PayloadAction<getDeviceList_returns_data_item[]>) => {
            return action.payload;
        },
        empty: (state: getDeviceList_returns_data_item[], action: PayloadAction<void>) => {
            return DeviceSliceInitialState;
        }
    }
});

export const { populate, empty } = DeviceSlice.actions;
export const DeviceReducer = DeviceSlice.reducer;