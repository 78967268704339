import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getDailyAQI_returns_data_item } from "../../api/getDailyAQI";
import { getTodaysData_Item } from "../../api/getTodaysData";

export type getDailyAQIData = getTodaysData_Item[];

export const DailyAQISliceInitialState: getDailyAQIData = [];

export const DailyAQISlice = createSlice({
  name: "daily",
  initialState: DailyAQISliceInitialState,
  reducers: {
    populate: (
      state: getDailyAQIData,
      action: PayloadAction<getDailyAQIData>
    ) => {
      return action.payload;
    },
    empty: (state: getDailyAQIData, action: PayloadAction<void>) => {
      return DailyAQISliceInitialState;
    },
  },
});

export const { populate, empty } = DailyAQISlice.actions;
export const DailyAQIReducer = DailyAQISlice.reducer;
