import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer, PersistConfig } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { DailyAQIReducer } from '../pages/daily/DailyAQISlice';
import { HourlyAQIReducer } from '../pages/hourly/HourlyAQISlice';
import UserReducer from '../pages/user/UserSlice';
import { DeviceReducer } from './DeviceSlice';
import { InputReducer } from './InputSlice';

const rootReducer = combineReducers({
  user: UserReducer,
  devices: DeviceReducer,
  hourly: HourlyAQIReducer,
  daily: DailyAQIReducer,
  input: InputReducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ['input','hourly', 'daily']
};


const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
